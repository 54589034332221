import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import * as Flickity from 'react-flickity-component'
import '~styles/flickity.css'
import Image from '~components/Image'
import { Rarr} from '~components/Svg'
import '@dotlottie/player-component'


const ColumnSlider = ({ className, image, lottie, featureColor, duration }) => {

	const [animation, setAnimation] = useState()
	const animationRef = useRef()

	useEffect(() => {
		if(animationRef?.current){
			const player = animationRef?.current
			setAnimation(player)
		}
	}, [animationRef])

	useEffect(() => {
		if (animation) {
			animation.play()
			animation.addEventListener('frame', e => {
				if(e.detail.frame > duration){
					animation.seek(0)
				}
			})
		}
	}, [animation])

	const flickityOptions = {
		arrowShape: 'M 0.00000838186 31.977 L 32.009 64 L 35.2536 60.7516 L 8.83643 34.3038 L 92 34.3038 L 92 29.6962 L 8.7904 29.6962 L 35.1846 3.27142 L 31.94 -4.13017e-7 L 0.0460324 31.9309 L 0.00000838186 31.977 Z'
	}

	return (
		<Wrap className={className}>
			<Flickity
				className={'carousel'} // default ''
				elementType={'div'} // default 'div'
				options={flickityOptions} // takes flickity options {}
				disableImagesLoaded={false} // default false
				reloadOnUpdate // default false
				static // default false
				css={css`
					.flickity-button-icon {
						fill: var(--black);
					}
					.flickity-prev-next-button.next {
						right: -7px;
					}
					.flickity-prev-next-button.previous {
						left: 7px;
					}
					.flickity-page-dots {
    				bottom: -40px;
						.dot {
							background: transparent;
							border: 1px solid ${featureColor};
							opacity: 1;
							width: 9px;
							height: 9px;
							margin: 0 5px;
							&.is-selected {
								background: ${featureColor};
							}
						}
					}
				`}
			>
				<Slide>
					<dotlottie-player
						ref={animationRef}
						src={lottie}
						autoplay
					/>
				</Slide>
				<Slide>
					<Overlay 
						css={css`
					background-color: ${featureColor};
				`}
					/>
					<FilteredImage 
						image={image} 
						aspectRatio={1}
					/>
				</Slide>
			</Flickity>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
`
const Slide = styled.div`
	position: relative;
	padding: 0 15%;
	width: 100%;
	box-sizing: border-box;
`
const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15%;
    right: 15%;
    z-index: 3;
    mix-blend-mode: screen;
		transition: opacity 0.25s;
`
const FilteredImage = styled(Image)`
	img {
		filter: grayscale(1);
	}
`

ColumnSlider.propTypes = {
	className: PropTypes.string,
	image: PropTypes.object,
	lottie: PropTypes.object,
	featureColor: PropTypes.string,
	duration: PropTypes.number,
}

export default ColumnSlider